import { inject, Injectable, Injector } from '@angular/core';
import { DialogService } from '@konnektu/components';
import { createContextLogger, isDefined } from '@konnektu/helpers';
import { JobsService } from '@konnektu/jobs';
import {
  DataType,
  from,
  getTableMetaByPath,
  injectObjectParameters,
  MetastoreSelectRequest,
  MetastoreService
} from '@konnektu/metastore';
import { injectTenantNavigate } from '@konnektu/multitenant';
import {
  SegmentResponseDto,
  SegmentsService,
  SegmentVersionDto
} from '@konnektu/segments-data';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom, tapResponse } from '@ngrx/operators';

import { TranslateService } from '@ngx-translate/core';
import { tuiIsPresent } from '@taiga-ui/cdk';
import { TuiAlertService, TuiDialogService } from '@taiga-ui/core';
import { TUI_PROMPT } from '@taiga-ui/kit';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import {
  catchError,
  concatMap,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil
} from 'rxjs';
import { SIMPLE_FILTER_STATE_METADATA_KEY } from '../segment-editor/constants';
import {
  SegmentEditorComponent,
  SegmentEditorDialogResult
} from '../segment-editor/segment-editor.component';
import { SegmentEditRawDialogComponent } from './dialog/edit-raw/edit-raw-dialog.component';
import {
  EditScheduleDialogComponent,
  EditScheduledResult
} from './dialog/edit-schedule/edit-schedule.dialog';

export interface SegmentCardComponentState {
  segment: SegmentResponseDto | null;
  versions: SegmentVersionDto[];
  isLoading: boolean;
}

@Injectable()
export class SegmentCardComponentStore extends ComponentStore<SegmentCardComponentState> {
  private readonly logger = createContextLogger('SegmentCardComponentStore');

  private readonly dialog = inject(DialogService);

  private readonly segments = inject(SegmentsService);

  private readonly jobs = inject(JobsService);

  private readonly translate = inject(TranslateService);

  private readonly injector = inject(Injector);

  private readonly tuiDialogs = inject(TuiDialogService);

  private readonly alert = inject(TuiAlertService);

  private readonly navigateTenant = injectTenantNavigate();

  segment$ = this.select((s) => s.segment);

  isLoading$ = this.select((s) => s.isLoading);

  versions$ = this.select((s) => s.versions);

  indexedColumns$ = this.select(
    (s) => s.segment?.index?.columns?.map((c) => c.name) ?? null
  );

  metadata$ = inject(MetastoreService).metadata({
    ui: { section: 'SegmentCardComponent' }
  });

  constructor() {
    super({
      segment: null,
      versions: [],
      isLoading: true
    });
  }

  recountCurrentSegment$ = this.effect<void>((source$) =>
    source$.pipe(
      switchMap(() =>
        this.tuiDialogs
          .open<boolean>(TUI_PROMPT, {
            data: {
              content: this.translate.instant('segmentRecount'),
              yes: this.translate.instant('Recount'),
              no: this.translate.instant('Cancel')
            }
          })
          .pipe(
            take(1),
            filter((e) => e),
            concatLatestFrom(() =>
              this.select((s) => s.segment).pipe(filter(isDefined))
            ),
            switchMap(([, segment]) =>
              this.jobs.recountSegment(segment.id).pipe(
                tapResponse(
                  () => {
                    this.showSuccessAlert(
                      this.translate.instant(
                        'segmentCard.segmentSentToRecountSuccessAlertText'
                      )
                    );
                  },
                  (err) => {
                    this.showErrorAlert(
                      this.translate.instant(
                        'segmentCard.segmentSentToRecountFailAlertText'
                      )
                    );
                    this.logger.error('Error while try recount segment', err);
                  }
                )
              )
            )
          )
      )
    )
  );

  editCurrentSegment$ = this.effect<void>((source$) =>
    source$
      .pipe(
        concatLatestFrom(() =>
          this.select((s) => s.segment).pipe(filter(isDefined))
        ),
        concatMap(([, segment]) => {
          if (segment.query.text.startsWith('{')) {
            const parsedQuery = JSON.parse(
              segment.query.text
            ) as MetastoreSelectRequest;

            return this.tuiDialogs
              .open<SegmentEditorDialogResult>(
                new PolymorpheusComponent(
                  SegmentEditorComponent,
                  this.injector
                ),
                {
                  size: 'page',
                  data: {
                    form: {
                      name: segment.name,
                      entityName: parsedQuery.query.$from,
                      selectedFields: parsedQuery.query['$select'],
                      disabledFields: segment.typeId ? ['Id'] : [],
                      typeId: segment.typeId,
                      indexedFields:
                        segment.index?.columns.map((c) => c.name) ?? [],
                      isIndexUnique: segment.index?.unique ?? false,
                      expression: injectObjectParameters(
                        parsedQuery.query.$where,
                        parsedQuery.parameters ?? {}
                      )
                    },
                    canEditFieldsAndExpression: !segment.currentVersion,
                    simpleFilterState: segment.metadata?.['simpleFilterState']
                  }
                }
              )
              .pipe(
                filter(tuiIsPresent),
                map((dialogRes) => ({ dialogRes, segment }))
              );
          } else {
            const dialog = this.dialog.openFromComponent(
              SegmentEditRawDialogComponent,
              { width: '80%', height: '80%' }
            );
            dialog.componentInstance.name = segment.name;
            dialog.componentInstance.rawSql = segment.query.text;
            return dialog.afterClosed.pipe(
              filter(isDefined),
              map((dialogRes) => ({ dialogRes, segment }))
            );
          }
        }),
        filter(isDefined),
        concatMap(({ dialogRes, segment }) => {
          if ('queryText' in dialogRes) {
            return this.segments.editRawSegment(segment.id, dialogRes);
          }
          const simpleFilterState = dialogRes.simpleFilterState;
          const formValue = dialogRes.form;

          if (segment.currentVersion) {
            return this.segments.editSegment(segment.id, {
              name: dialogRes.form.name as string
            });
          }

          const serializedQuery = from(formValue.entityName as string)
            .select(formValue.selectedFields as string[])
            .where(formValue.expression ?? undefined)
            .done();

          return (
            formValue.indexedFields?.length
              ? forkJoin(
                  formValue.indexedFields.map((f) =>
                    getTableMetaByPath(
                      this.metadata$,
                      formValue.entityName as string,
                      f.split('.')
                    ).pipe(map((meta) => ({ meta, field: f })))
                  )
                )
              : of([])
          ).pipe(
            concatMap((cols) => {
              const indexColumns = cols
                .filter((c) => typeof c.meta === 'number')
                .map((c) => ({
                  name: c.field,
                  type: c.meta as DataType
                }));
              return this.segments.editSegment(segment.id, {
                name: formValue.name,
                type: 1,
                query: {
                  text: {
                    parameters: serializedQuery.parameters,
                    query: {
                      $from: serializedQuery.query.$from,
                      $select: serializedQuery.query.$select,
                      $where: serializedQuery.query.$where
                    }
                  },
                  source: 1
                },
                index: {
                  columns: indexColumns,
                  unique: formValue.isIndexUnique as boolean
                }
              });
            }),
            concatMap((editedSegment) => {
              if (simpleFilterState) {
                return this.segments
                  .updateSegmentMetadata(
                    editedSegment.id,
                    SIMPLE_FILTER_STATE_METADATA_KEY,
                    simpleFilterState
                  )
                  .pipe(
                    map(() => ({
                      ...editedSegment,
                      metadata: { simpleFilterState }
                    }))
                  );
              }
              return of({ ...editedSegment, metadata: segment.metadata });
            })
          );
        })
      )
      .pipe(
        tapResponse(
          (newSegment) => this.patchState({ segment: newSegment }),
          (err) => this.logger.error('Failed to edit current segment', err)
        )
      )
  );

  loadSegmentAndVersions$ = this.effect<number>((segmentId$) =>
    segmentId$.pipe(
      switchMap((segmentId) =>
        this.segments.byId(segmentId).pipe(
          finalize(() => this.patchState({ isLoading: false })),
          catchError((_, caught) => {
            void this.navigateTenant(['404'], { skipLocationChange: true });
            return caught;
          }),
          tapResponse(
            (segmentResponse) => this.patchState({ segment: segmentResponse }),
            (err) => this.logger.error('Error while receiving segment', err)
          )
        )
      ),
      mergeMap((segment) =>
        this.segments.segmentVersions(segment.id).pipe(
          map((version) =>
            version.items.sort(
              (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
          ),
          tapResponse(
            (versions) => this.patchState({ versions: versions.slice(0, 50) }),
            (err) => this.logger.error('Error receiving segment versions', err)
          )
        )
      )
    )
  );

  editSegmentSchedule$ = this.effect<[string, string] | null>(
    (scheduleIdAndCron$) =>
      scheduleIdAndCron$.pipe(
        switchMap((value) =>
          this.tuiDialogs
            .open<boolean>(TUI_PROMPT, {
              data: {
                content: this.translate.instant('segmentRecount'),
                yes: this.translate.instant('Recount'),
                no: this.translate.instant('Cancel')
              }
            })
            .pipe(
              take(1),
              filter((edit) => edit),
              map(() => value),
              concatLatestFrom(() => this.segment$.pipe(filter(isDefined))),
              switchMap(([scheduleIdAndCron, segment]) =>
                this.tuiDialogs
                  .open<EditScheduledResult>(
                    new PolymorpheusComponent(EditScheduleDialogComponent),
                    {
                      dismissible: true,
                      closeable: true,
                      data: {
                        scheduleId: scheduleIdAndCron
                          ? scheduleIdAndCron[0]
                          : null,
                        cron: scheduleIdAndCron ? scheduleIdAndCron[1] : null
                      }
                    }
                  )
                  .pipe(
                    filter(isDefined),
                    switchMap((result) => {
                      if (result === 'cancel') {
                        return EMPTY;
                      }
                      if (result === 'remove') {
                        if (scheduleIdAndCron && scheduleIdAndCron[0]) {
                          return this.jobs
                            .deleteSchedule(scheduleIdAndCron[0])
                            .pipe(
                              tapResponse(
                                () =>
                                  this.showSuccessAlert(
                                    this.translate.instant(
                                      'segmentCard.segmentRecountJobRemoveSuccessAlertText'
                                    )
                                  ),
                                (err) => {
                                  this.showErrorAlert(
                                    this.translate.instant(
                                      'segmentCard.segmentRecountJobRemoveFailAlertText'
                                    )
                                  );
                                  this.logger.error('Error removing job', err);
                                }
                              )
                            );
                        }
                        return EMPTY;
                      } else {
                        return (
                          scheduleIdAndCron
                            ? this.jobs.deleteSchedule(scheduleIdAndCron[0])
                            : of(true)
                        ).pipe(
                          switchMap((removed) => {
                            if (removed) {
                              return this.jobs
                                .scheduleSegmentRecount(segment.id, result.cron)
                                .pipe(
                                  tapResponse(
                                    () =>
                                      this.showSuccessAlert(
                                        this.translate.instant(
                                          'segmentCard.segmentRecountJobRescheduleSuccessAlertText'
                                        )
                                      ),
                                    (err) => {
                                      this.showErrorAlert(
                                        this.translate.instant(
                                          'segmentCard.segmentRecountJobRescheduleFailAlertText'
                                        )
                                      );
                                      this.logger.error(
                                        'Failed to reschedule segment recount',
                                        err
                                      );
                                    }
                                  )
                                );
                            }
                            this.showErrorAlert(
                              this.translate.instant(
                                'segmentCard.segmentRecountJobRemoveFailAlertText'
                              )
                            );
                            return EMPTY;
                          })
                        );
                      }
                    })
                  )
              )
            )
        )
      )
  );

  private showErrorAlert(text: string) {
    this.alert
      .open(text, { status: 'error' })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private showSuccessAlert(text: string) {
    this.alert
      .open(text, { status: 'success' })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
